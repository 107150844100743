import "./index.scss"
import "./Icons"

import Togglable from "./plugins/Togglable/Togglable"
import GAEventListener from "./plugins/GAEventListener/GAEventListener"
import ShareDialog from "./plugins/ShareDialog/ShareDialog"


function initPlugins() {
  /*
   * Initiate each plugin on the page.
   */
  document
    .querySelectorAll(".js-togglable")
    .forEach(function (el) {new Togglable(el)})

  document
    .querySelectorAll(".js-ga-event-listener")
    .forEach(function (el) {new GAEventListener(el)})

  document
    .querySelectorAll(".js-share-dialog")
    .forEach(function (el) {new ShareDialog(el)})
}


window.addEventListener("load", initPlugins)
