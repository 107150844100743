import $ from "jquery"


class GAEventListener {
  constructor (el) {
    this.el = el
    this.action = $(el).data().action || "click"
    this.category = $(el).data().category || "link"
    this.eventLabel = $(el).data().eventLabel
    this.logEvent = this.logEvent.bind(this)
    $(el).click(this.logEvent)
  }

  logEvent () {
    window.gtag(
      "event",
      this.action,
      {
        "event_category": this.category,
        "event_label": this.eventLabel
      }
    )
  }

}


export default GAEventListener
