import $ from "jquery"


class Togglable {
  constructor (el) {
    this.el = el
    this.toggle = this.toggle.bind(this)
    this.animate = this.animate.bind(this)

    this.target = $($(el).data().target)
    this.visible = $(el).hasClass("togglable--on")
    if (!this.visible) {this.animate()}

    $(el).click(this.toggle)
  }

  toggle () {
    this.visible = !this.visible
    this.animate()
  }

  animate () {
    var $el = $(this.el)
    var visible = this.visible

    var toggleSwitch = function(){
      $el.toggleClass("togglable--on", visible).toggleClass("togglable--off", !visible)
    }
    if (this.visible) {
      this.target.slideDown(toggleSwitch)
    } else {
      this.target.slideUp(toggleSwitch)
    }
  }

}


export default Togglable
