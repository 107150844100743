import $ from "jquery"


const DIALOG_CONFIGS = {
  facebook: {width: 600, height: 300},
  twitter: {width: 600, height: 254},
  google: {width: 515, height: 490},
  linkedin: {width: 600, height: 473}
}


class ShareDialog {

  constructor (el) {
    this.el = el
    this.showShareDialog = this.showShareDialog.bind(this)

    var $el = $(el)
    this.provider = $el.data.provider || "facebook"
    this.link = $el.attr("href")
    var dialogConfig = DIALOG_CONFIGS[this.provider]
    this.dialogConfigString = (
      "menubar=no,toolbar=no," +
      "height=" + dialogConfig.height + "," +
      "width=" + dialogConfig.width
    )

    $(el).click(this.showShareDialog)
  }

  showShareDialog (e) {
    e.preventDefault()
    window.open(this.link, "shareDialog", this.dialogConfigString)
    return false
  }

}

export default ShareDialog
