import fontawesome from "@fortawesome/fontawesome"
import faAngleLeft from "@fortawesome/fontawesome-free-solid/faAngleLeft"
import faAngleDoubleLeft from "@fortawesome/fontawesome-free-solid/faAngleDoubleLeft"
import faAngleRight from "@fortawesome/fontawesome-free-solid/faAngleRight"
import faAngleDoubleRight from "@fortawesome/fontawesome-free-solid/faAngleDoubleRight"
import faSearch from "@fortawesome/fontawesome-free-solid/faSearch"
import faCheck from "@fortawesome/fontawesome-free-solid/faCheck"
import faUsers from "@fortawesome/fontawesome-free-solid/faUsers"
import faClock from "@fortawesome/fontawesome-free-solid/faClock"
import faChild from "@fortawesome/fontawesome-free-solid/faChild"
import faSmile from "@fortawesome/fontawesome-free-solid/faSmile"
import faFacebook from "@fortawesome/fontawesome-free-brands/faFacebook"
import faFacebookF from "@fortawesome/fontawesome-free-brands/faFacebookF"
import faTwitter from "@fortawesome/fontawesome-free-brands/faTwitter"
import faTwitterSquare from "@fortawesome/fontawesome-free-brands/faTwitterSquare"


// Add all the font awesome icons to the library
fontawesome.library.add(faAngleLeft)
fontawesome.library.add(faAngleDoubleLeft)
fontawesome.library.add(faAngleRight)
fontawesome.library.add(faAngleDoubleRight)
fontawesome.library.add(faSearch)
fontawesome.library.add(faCheck)
fontawesome.library.add(faUsers)
fontawesome.library.add(faClock)
fontawesome.library.add(faChild)
fontawesome.library.add(faSmile)
fontawesome.library.add(faFacebook)
fontawesome.library.add(faTwitter)
fontawesome.library.add(faFacebookF)
fontawesome.library.add(faTwitterSquare)
